<template>
  <LoginForm Heading = 'Logins With New Hierarchies' @submit="submit"/>
</template>

<script>
import LoginForm from './Form.vue'
import { mapState, mapActions } from 'vuex'
import { DataForMatomoTracking, initializeNikshayMatomo, adminLogData } from '../../utils/matomoTracking'
import { LoginsWithNewHierarchiesValidation } from './../../utils/NikshayValidations/LoginsWithNewHierarchiesValidation'
import { apiResponseType } from '../../utils/dataValidations'
import { ApiClient } from '../store/api'
const FormatLoginsWithNewHierarchiesData = require("./../../utils/NikshayValidations/FormatLoginsWithNewHierarchiesData")
const csv = require("papaparse")
export default {
  components: { LoginForm },
  computed: {
    ...mapState([
      'isProd'
    ])
  },
  mounted: function () {
    initializeNikshayMatomo()
  },
  methods: {
    ...mapActions([
      'loading',
      'setErrorMessage',
      'success'
    ]),
    async submit (file, filename, username, password) {
      this.loading(true)
      this.setErrorMessage({ isError: false, errMsg: '' })
      this.success({ isOk: false, succMsg: '' })

      let data = null
      const parsedCSV = csv.parse(file, {
        complete: async function (results){
          const validationError = LoginsWithNewHierarchiesValidation(results.data)
          if (validationError){
            this.setErrorMessage({ isError: true, errMsg: validationError })
            this.loading(false)
          } else {
            data = FormatLoginsWithNewHierarchiesData(results.data)
            let jsonUploadResponse
            try {
              jsonUploadResponse = await ApiClient.post(
                username,
                password,
                JSON.stringify(data),
                '/api/Hierarchy/LoginsWithNewHierarchies'
              )
            } catch (error) {
              this.setErrorMessage({ isError: true, errMsg: apiResponseType.JSONUploadFailed })
            }
            this.loading(false)
            if (jsonUploadResponse){
              if (jsonUploadResponse.data.success === true && jsonUploadResponse.data.data.failedLoginsWithNewHierarchiesList == null){
                this.setErrorMessage({ isError: false, errMsg: '' })
                this.success({ isOk: true, succMsg: 'Logins With Hierarchies are created.' })
              } else if (jsonUploadResponse.data.error != null){
                this.setErrorMessage({ isError: true, errMsg: jsonUploadResponse.data.error })
              } else if (Array.isArray(jsonUploadResponse.data.data.failedLoginsWithNewHierarchiesList)) {
                var arrayobj = jsonUploadResponse.data.data.failedLoginsWithNewHierarchiesList

                var outputlist = arrayobj.map(function (item) {
                  return "[" + item.username + " : " + item.phoneNumber + "]" + "\n"
                })

                this.setErrorMessage({ isError: true, errMsg: outputlist + ' following logins were not created , please verify the entries and try again.' })
              } else {
                this.setErrorMessage({ isError: true, errMsg: 'Something went wrong' })
              }
              adminLogData('Logins With New Hierarchies', 'StatusCode: ' + jsonUploadResponse.status, 'Filename: ' + filename)

              DataForMatomoTracking('Logins With New Hierarchies', apiResponseType.SUCCESS, jsonUploadResponse.data.data.successLoginsWithNewHierarchiesList)
              DataForMatomoTracking('Logins With New Hierarchies', apiResponseType.FAILED, jsonUploadResponse.data.data.failedLoginsWithNewHierarchiesList)
            }
          }
        }.bind(this)
      })
    }
  }
}
</script>
