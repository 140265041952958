function FormatLoginsWithNewHierarchiesData (csvFileAsArrays) {
// TODO : Use a common function to format all different types of data
  const fileRows = [...csvFileAsArrays]
  const listToSend = []
  fileRows.reduce((acc, row) => {
    const dataObj = {}
    dataObj[fileRows[0][0]] = row[0]
    dataObj[fileRows[0][1]] = row[1]
    dataObj[fileRows[0][2]] = row[2]
    dataObj[fileRows[0][3]] = row[3]
    dataObj[fileRows[0][4]] = row[4]
    dataObj[fileRows[0][5]] = row[5]
    dataObj[fileRows[0][6]] = row[6]
    dataObj[fileRows[0][7]] = row[7]
    dataObj[fileRows[0][8]] = row[8]
    dataObj[fileRows[0][9]] = row[9]
    dataObj[fileRows[0][10]] = row[10]
    dataObj[fileRows[0][11]] = row[11]
    dataObj[fileRows[0][12]] = row[12]
    dataObj[fileRows[0][13]] = row[13]
    dataObj[fileRows[0][14]] = row[14]
    dataObj[fileRows[0][15]] = row[15]
    dataObj[fileRows[0][16]] = row[16]
    dataObj[fileRows[0][17]] = row[17]
    dataObj[fileRows[0][18]] = row[18]
    dataObj[fileRows[0][19]] = row[19]
    dataObj[fileRows[0][20]] = row[20]
    dataObj[fileRows[0][21]] = row[21]
    listToSend.push(dataObj)
  })
  return listToSend
}
module.exports = FormatLoginsWithNewHierarchiesData
