import { TypeOfPatientsAdded, TypeOfStaffAllowedToAdd, TrueorFalse, HierarchyType, DrugRegimen } from '../dataValidations'
export const LoginsWithNewHierarchiesValidation = (rows) => {
  // Validate headers
  const rowError = validateCsvHeadings(rows[0])
  if (rowError) {
    return `${rowError}`
  }
  // validate entries
  const dataRows = rows.slice(1, rows.length) // ignore header at 0 and get rest of the rows

  if (dataRows.length > 30){
    return 'Please create Logins with upto 30 entries at a time in csv'
  }

  for (let i = 0; i < dataRows.length; i++) {
    const rowError = validateCsvRow(dataRows[i])
    if (rowError) {
      return `${rowError} on row ${i + 2}`
    }
  }

  const ppsaUsersFound = rows.some(
    (innerArray) =>
      innerArray[11] === 'PPSA-HUB-AGENT' || innerArray[11] === 'PPSA-ADMIN'
  )
  if (ppsaUsersFound) {
    return 'Smart Payments enabled logins(PPSA-ADMIN and PPSA-HUB-AGENT) creations is disabled form Admin Module, Please raise a request for creation of these users'
  }
}
function validateCsvHeadings (row) {
  if (!row[0] || !/^Username$/.test(row[0])){
    return "Column 1 heading should be Username"
  } else if (!row[1] || !/^Password$/.test(row[1])){
    return "Column 2 heading should be Password"
  } else if (!row[2] || !/^PhoneNumber$/.test(row[2])){
    return "Column 3 heading should be PhoneNumber"
  } else if (!row[3] || !/^SecondaryPhoneNumber$/.test(row[3])){
    return "Column 4 heading should be SecondaryPhoneNumber"
  } else if (!row[4] || !/^EmailAddress$/.test(row[4])){
    return "Column 5 heading should be EmailAddress"
  } else if (!row[5] || !/^TypeOfPatientsAdded$/.test(row[5])){
    return "Column 6 heading should be TypeOfPatientsAdded"
  } else if (!row[6] || !/^ViewOnly$/.test(row[6])){
    return "Column 7 heading should be ViewOnly"
  } else if (!row[7] || !/^CanDeletePatients$/.test(row[7])){
    return "Column 8 heading should be CanDeletePatients"
  } else if (!row[8] || !/^AccessIndiaTbPublic$/.test(row[8])){
    return "Column 9 heading should be AccessIndiaTbPublic"
  } else if (!row[9] || !/^AccessIndiaTbPrivate$/.test(row[9])){
    return "Column 10 heading should be AccessIndiaTbPrivate"
  } else if (!row[10] || !/^TypeOfStaffAllowedToAdd$/.test(row[10])){
    return "Column 11 heading should be TypeOfStaffAllowedToAdd"
  } else if (!row[11] || !/^AccessType$/.test(row[11])){
    return "Column 12 heading should be AccessType"
  } else if (!row[12] || !/^ParentHierarchyId$/.test(row[12])){
    return "Column 13 heading should be ParentHierarchyId"
  } else if (!row[13] || !/^HierarchyLevel$/.test(row[13])){
    return "Column 14 heading should be HierarchyLevel"
  } else if (!row[14] || !/^HierarchyCode$/.test(row[14])){
    return "Column 15 heading should be HierarchyCode"
  } else if (!row[15] || !/^HierarchyType$/.test(row[15])){
    return "Column 16 heading should be HierarchyType"
  } else if (!row[16] || !/^HasChildren$/.test(row[16])){
    return "Column 17 heading should be HasChildren"
  } else if (!row[17] || !/^HasMERM$/.test(row[17])){
    return "Column 18 heading should be HasMERM"
  } else if (!row[18] || !/^IsNotActive$/.test(row[18])){
    return "Column 19 heading should be IsNotActive"
  } else if (!row[19] || !/^IsTribal$/.test(row[19])){
    return "Column 20 heading should be IsTribal"
  } else if (!row[20] || !/^EligibleForIncentive$/.test(row[20])){
    return "Column 21 heading should be EligibleForIncentive"
  } else if (!row[21] || !/^DrugRegimen$/.test(row[21])){
    return "Column 22 heading should be DrugRegimen"
  }
}

function validateCsvRow (row) {
  if (!row[0] || !/^([a-zA-Z0-9]*-* *_*[a-zA-Z0-9]*)+$/.test(row[0])){
    return "Column 1 should have a valid Username"
  } else if (!row[2] || !/^[0-9]*$/.test(row[2])){
    return "Column 3 should have a valid PhoneNumber"
  } else if (!row[5] || !TypeOfPatientsAdded(row[5])){
    return "Column 6 should have a valid TypeOfPatientsAdded"
  } else if (!row[6] || !TrueorFalse(row[6])){
    return "Column 7 should have a valid ViewOnly"
  } else if (!row[7] || !TrueorFalse(row[7])){
    return "Column 8 should have a valid CanDeletePatients"
  } else if (!row[8] || !TrueorFalse(row[8])){
    return "Column 9 should have a valid AccessIndiaTbPublic"
  } else if (!row[9] || !TrueorFalse(row[9])){
    return "Column 10 should have a valid AccessIndiaTbPrivate"
  } else if (!row[10] || !TypeOfStaffAllowedToAdd(row[10])){
    return "Column 11 should have a valid TypeOfStaffAllowedToAdd"
  } else if (!row[11] || !/^([A-Z]*-*_*[A-Z]*)+$/.test(row[11])){
    return "Column 12 should have a valid AccessType"
  } else if (/^STAFF$/.test(row[11])) {
    return "Please use Nikshay to create STAFF logins"
  } else if (!row[12] || !/^[0-9]*$/.test(row[12])){
    return "Column 13 should have a valid ParentHierarchyId"
  } else if (!row[13] || !/^[1-5]{1}$/.test(row[13])){
    return "Column 14 should have a valid HierarchyLevel"
  } else if (!row[14] || !/^[A-Z]*$/.test(row[14])){
    return "Column 15 must contain a valid HierarchyCode. A valid HierarchyCode should consist of characters from 'A' to 'Z' all caps"
  } else if (!row[15] || !HierarchyType(row[15])){
    return "Column 16 should have a valid HierarchyType"
  } else if (!row[16] || !TrueorFalse(row[16])){
    return "Column 17 should have a valid HasChildren"
  } else if (!row[17] || !TrueorFalse(row[17])){
    return "Column 18 should have a valid HasMERM"
  } else if (!row[18] || !TrueorFalse(row[18])){
    return "Column 19 should have a valid IsNotActive"
  } else if (!row[19] || !TrueorFalse(row[19])){
    return "Column 20 should have a valid IsTribal"
  } else if (!row[20] || !TrueorFalse(row[20])){
    return "Column 21 should have a valid EligibleForIncentive"
  } else if (!row[21] || !DrugRegimen(row[21])){
    return "Column 22 should have a valid DrugRegimen"
  }
}
